import * as React from 'react';
import Header from '@src/components/Header';
import Layout from '@src/components/Layout';
import TopContainer from '@src/top';

class Top extends React.Component<{}> {
  render() {
    return (
      <React.Fragment>
        <Header title="取引履歴" disableKarteScript />
        <Layout>
          <TopContainer />
        </Layout>
      </React.Fragment>
    );
  }
}

export default Top;
