import React, { PureComponent } from 'react';
import { IEasyPay, ITransactionItem } from '@src/interfaces/ITransactionState';
import { formatMoney, getTimeFromTimeServer } from '@src/utils/format';
import { isStartTimeOfDay } from '@src/utils/functionUtils';

interface PayNetEasyPayOtherInfoProps {
  data: ITransactionItem;
}

export class PayNetEasyPayOtherInfo extends PureComponent<PayNetEasyPayOtherInfoProps> {
  // API returns 2 format types (YYYY-MM-DDT00:00:00Z or YYYY-MM-DDThh:mm:ssZ)
  formatShowingDate = (datetime: string) => {
    if (!datetime) return '';
    const { year, month, day, time } = getTimeFromTimeServer(datetime.replace('Z', '+09:00'));
    const regex = /^([\d]{1,2}):([\d]{1,2}):([\d]{1,2})/;
    const parseTime = time ? time.match(regex) : '';
    const { hour, minute } = parseTime && parseTime.length
      ? { hour: parseTime[1], minute: parseTime[2] }
      : { hour: '00', minute: '00' };

    return isStartTimeOfDay(datetime) ? `${year}/${month}/${day}` : `${year}/${month}/${day} ${hour}:${minute}`;
  }

  render() {
    const data = this.props.data;
    const initEasyPay = { used_date: '', paymnt_method_cd: '', paymnt_method_name: '', used_amount: 0 };
    const { easy_pay_list = [], pay_info_no } = this.props.data.easy_info || {};
    const easyPayLength = easy_pay_list.length;
    const latestEasyPay: IEasyPay = easyPayLength ? easy_pay_list[easyPayLength - 1] : initEasyPay;

    return (
      <div className="other-informations">
        <div className="list-form-rows">
          { easyPayLength === 0
            ? <div className="form-row-no-border"> 備考データはありません</div>
            : <>
              <div className="form-row-no-border">備考</div>
              {easy_pay_list.map((item: IEasyPay, idx: number) => (
                <div className="form-row-no-border" key={idx}>
                  【{item.paymnt_method_name}】
                  {formatMoney(Math.abs(item.used_amount))}円 {this.formatShowingDate(item.used_date)}
                </div>
              ))}
              { pay_info_no && <div className="form-row-no-border">【伝票番号】{pay_info_no}</div>}
              { data.inq_to &&
                <div className="form-row-no-border">【問合せ先】{data.inq_to}</div>
              }
              { <div className="form-row-no-border">【種別】{latestEasyPay.paymnt_method_name}</div>}
            </>
          }
        </div>
      </div>
    );
  }
}
