import { IAccountItem, IAccountInfo } from '@src/interfaces/IAccountState';

export const actionTypes = {
  LOAD_ACCOUNT: 'LOAD_ACCOUNT',
  LOAD_ACCOUNT_PENDING: 'LOAD_ACCOUNT_PENDING',
  LOAD_ACCOUNT_SUCCESS: 'LOAD_ACCOUNT_SUCCESS',
  LOAD_ACCOUNT_DETAIL: 'LOAD_ACCOUNT_DETAIL',
  LOAD_ACCOUNT_DETAIL_SUCCESS: 'LOAD_ACCOUNT_DETAIL_SUCCESS',
  HIDE_ACCOUNT: 'HIDE_ACCOUNT',
  LOAD_ACCOUNT_LIST: 'LOAD_ACCOUNT_LIST',
  LOAD_ACCOUNT_LIST_SUCCESS: 'LOAD_ACCOUNT_LIST_SUCCESS',
  RESET_ACCOUNT_INFO: 'RESET_ACCOUNT_INFO',
  DELETE_ACCOUNT: 'DELETE_ACCOUNT'
};

export function loadDataAccount() {
  return {
    type: actionTypes.LOAD_ACCOUNT
  };
}

export function loadDataAccountPending() {
  return {
    type: actionTypes.LOAD_ACCOUNT_PENDING
  };
}

export function loadDataAccountSuccess(account: IAccountItem) {
  return {
    type: actionTypes.LOAD_ACCOUNT_SUCCESS,
    account: account
  };
}

export function loadDataAccountDetail(id: string) {
  return {
    type: actionTypes.LOAD_ACCOUNT_DETAIL,
    id
  };
}

export function loadDataAccountDetailSuccess(account: IAccountInfo) {
  return {
    type: actionTypes.LOAD_ACCOUNT_DETAIL_SUCCESS,
    account
  };
}

export function hideAccount(id: string, value: boolean) {
  return {
    type: actionTypes.HIDE_ACCOUNT,
    id,
    is_hide: value
  };
}

export function loadDataAccountList() {
  return {
    type: actionTypes.LOAD_ACCOUNT_LIST
  };
}

export function loadDataAccountListSuccess(account: IAccountItem) {
  return {
    type: actionTypes.LOAD_ACCOUNT_LIST_SUCCESS,
    account
  };
}

export function resetAccountInfo() {
  return {
    type: actionTypes.RESET_ACCOUNT_INFO
  };
}

export function deleteAccount(id: string) {
  return {
    type: actionTypes.DELETE_ACCOUNT,
    id
  };
}
