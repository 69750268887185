import React, { PureComponent } from 'react';
import moment from '@src/utils/kpfmMoment';
import { IQrDetail, ITransactionItem } from '@src/interfaces/ITransactionState';
import { formatMoney } from '@src/utils/format';

interface PayNetQrOtherInfoProps {
  data: ITransactionItem;
}

export class PayNetQrOtherInfo extends PureComponent<PayNetQrOtherInfoProps> {
  render() {
    const { data } = this.props;
    const { qr_info, cpn_apply_type, cpn_id, repay_diff_reason } = data;
    const { qr_list = [] } = qr_info || {};
    const qrListLength = qr_list.length;
    const lastestItem = qrListLength ? qr_list[qrListLength - 1] : { proc_type_name: '' };

    return (
      <div className="other-informations">
        <div className="list-form-rows">
          { qrListLength === 0
            ? <div className="form-row-no-border"> 備考データはありません</div>
            : <>
              <div className="form-row-no-border">備考</div>
              {qr_list.map((item: IQrDetail, idx: number) => (
                <div className="form-row-no-border" key={idx}>
                  【{item.proc_type_name}】
                  {formatMoney(Math.abs(item.used_amount))}円 {moment(item.proc_d_time, 'YYYYMMDDhhmmss').format('YYYY/MM/DD HH:mm')}
                </div>
              ))}
              { data.voucher_no &&
                <div className="form-row-no-border">【伝票番号】{data.voucher_no}</div>
              }

              { data.inq_to &&
                <div className="form-row-no-border">【問合せ先】{data.inq_to}</div>
              }
              { lastestItem.proc_type_name &&
                <div className="form-row-no-border">【種別】{lastestItem.proc_type_name}</div>
              }

              { (cpn_apply_type === '3') &&
                <div className="form-row-no-border">【クーポン情報】条件を満たしていないためご利用できませんでした</div>
              }

              { (cpn_apply_type !== '3' && cpn_apply_type !== '1' && cpn_apply_type !== '-1') &&
                <div className="form-row-no-border">
                  { cpn_id &&
                    <>
                      【クーポン情報】<br />
                      クーポン番号 {cpn_id} <br/>
                    </>
                  }
                  { repay_diff_reason === '1' && <>【返金金額差分の理由】クーポン適用のお買い物であったため<br /></> }
                </div>
              }
            </>
          }
        </div>
      </div>
    );
  }
}
