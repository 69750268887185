import React, { PureComponent } from 'react';
import { IMarketProduction, ITransactionItem } from '@src/interfaces/ITransactionState';
import { formatMoney } from '@src/utils/format';

interface AuMarketOtherInfoProps {
  data: ITransactionItem;
}

export class AuMarketOtherInfo extends PureComponent<AuMarketOtherInfoProps> {
  render() {
    const { data } = this.props;
    const marketDetail = data.market_detail;

    return (
      <div className="other-informations">
        <div className="list-form-rows">
          { !marketDetail
            ? <div className="form-row-no-border"> 備考データはありません</div>
            : <>
              <div className="form-row-no-border">備考</div>
              <div className="form-row-no-border">【商品名】
                {marketDetail.items.map((item: IMarketProduction) => (
                  <div className="form-row-no-border __sub-infor" key={item.id}>{item.name} ({formatMoney(item.price)}円 * {item.quantity})</div>
                ))}
              </div>
              <div className="form-row-no-border">【店舗名】{data.store_name}</div>
              <div className="form-row-no-border">【有料明細】{formatMoney(marketDetail.paid_statement)}円</div>
              <div className="form-row-no-border">【送料】{formatMoney(marketDetail.shipping)}円</div>
              <div className="form-row-no-border">【利用クーポン】{formatMoney(marketDetail.usage_coupon)}円</div>
              <div className="form-row-no-border">【ポイント利用数】{formatMoney(marketDetail.used_au_point)}P</div>
            </>
          }
        </div>
      </div>
    );
  }
}
