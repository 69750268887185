import { formatMoney } from '@src/utils/format';
import { getShowingMonth } from '@src/utils/functionUtils';
import moment from '@src/utils/kpfmMoment';
import React from 'react';

interface Prop {
  time: string,
  totalExpense: number | string,
  totalIncome: number | string,
  totalExpenseText: string,
  totalIncomeText: string,
  balanceUnit: string,
  handleCalendarModal(): void
}

const AuPaySummary: React.FC<Prop> = ({
  handleCalendarModal,
  time,
  totalExpense,
  totalIncome,
  totalExpenseText,
  totalIncomeText,
  balanceUnit
}: Prop) => {
  return (
    <div className="history-container-content">
      <div className="asset-summaries bg-white">
        <div className="row-flex asset-summaries__title" onClick={handleCalendarModal}>
          <div className="asset-month">
            <span className='font-roboto'>{getShowingMonth(moment(time, 'YYYY-MM').format('MM'))}</span>
            <span className="medium-total-number-text">月</span>
          </div>
          <i className="kicon-arrow-down--dark"></i>
        </div>
        <div className="asset-summaries__list">
          <div className="row-flex border-bottom-line ">
            <p className="font-size-12 bold-text black-text">{totalIncomeText}</p>
            <div className="nowrap-text money ">
              <span className="money-amount-text amount-text green-text font-roboto">
                {formatMoney(totalIncome)}
                <span className="money-unit-text unit-text">{balanceUnit}</span>
              </span>
            </div>
          </div>
          <div className="row-flex border-bottom-line ">
            <p className="font-size-12 bold-text black-text">{totalExpenseText}</p>
            <div className="nowrap-text money ">
              <span className="money-amount-text amount-text font-roboto red-text">
                {formatMoney(totalExpense)}
                <span className="money-unit-text unit-text">{balanceUnit}</span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuPaySummary;
