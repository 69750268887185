import React, { PureComponent } from 'react';
import moment from '@src/utils/kpfmMoment';
import { ITransactionItem } from '@src/interfaces/ITransactionState';
import { formatMoney } from '@src/utils/format';
import { getAccountType, isPayNetEasyPayTran, isPayNetQrTran } from '@src/utils/functionUtils';
import { AccountType } from '@src/utils/appContanst';
import { PayNetEasyPayOtherInfo } from './PayNetEasyPayOtherInfo';
import { AuMarketOtherInfo } from './AuMarketOtherInfo';
import { PayNetQrOtherInfo } from './PayNetQrOtherInfo';
import { KantanKessaiOtherInfo } from './KantanKessaiOtherInfo';

interface OtherInfoProps {
  data: ITransactionItem;
}

export class OtherInfo extends PureComponent<OtherInfoProps> {
  enableOtherInfo(cardType: string) {
    const { data } = this.props;

    if (cardType === AccountType.au_ponta) return false;
    if (data.history_date || data.proc_d_time || data.voucher_no ||
        data.inq_to || data.tel_no || data.process_name || data.proc_type_name ||
        data.cpn_id || data.cpn_apply_type || data.dscnt_amt ||
        (data.campaign_info && data.campaign_info.has_campaign)) {
      return true;
    }
    return false;
  }

  render() {
    const { data } = this.props;
    const cardType = getAccountType(data.data_source);
    const { easy_info, qr_info } = data;

    if (isPayNetEasyPayTran(easy_info)) {
      return <PayNetEasyPayOtherInfo data={data}/>;
    } else if (cardType === AccountType.au_market) {
      return <AuMarketOtherInfo data={data} />;
    } else if (isPayNetQrTran(qr_info)) {
      return <PayNetQrOtherInfo data={data}/>;
    } else if (cardType == AccountType.au_kantan_kessai) {
      return <KantanKessaiOtherInfo data={data} />;
    }
    if (!this.enableOtherInfo(cardType)) {
      return (
        <div className="other-informations">
          <div className="list-form-rows">
            <div className="form-row-no-border"> 備考データはありません</div>
          </div>
        </div>
      );
    }

    const {
      history_date,
      proc_type = '',
      proc_d_time,
      cpn_apply_type,
      campaign_info,
      dscnt_amt,
      repay_diff_reason,
      cpn_id
    } = data;
    const historyDate = history_date || proc_d_time;
    // '0005', '0006', '2005' is refund of PayNetQr
    // refund PayNetQr will display like normal transaction
    const isRefund = ['0005', '0006', '2005'].includes(proc_type);
    const qrList = qr_info ? qr_info.qr_list : [];
    const refundItem = qrList && qrList.length ? qrList[qrList.length - 1] : undefined;

    return (
      <div className="other-informations">
        <div className="list-form-rows">
          <div className="form-row-no-border">備考</div>
          {isRefund && !!refundItem &&
            <div className="form-row-no-border">
              【{refundItem.proc_type_name}】
              {formatMoney(Math.abs(refundItem.used_amount))}円 {moment(refundItem.proc_d_time, 'YYYYMMDDhhmmss').format('YYYY/MM/DD HH:mm')}
            </div>
          }
          { !isRefund && historyDate &&
            <div className="form-row-no-border">【利用日時】{ moment(historyDate, 'YYYY-MM-DDTHH:ss:ii+Z').format('YYYY/MM/DD HH:ss') }</div>
          }

          { data.voucher_no &&
            <div className="form-row-no-border">【伝票番号】{data.voucher_no}</div>
          }

          { data.inq_to &&
            <div className="form-row-no-border">【問合せ先】{data.inq_to}</div>
          }

          { data.tel_no &&
            <div className="form-row-no-border">【送金・着金相手情報】{data.tel_no}</div>
          }

          { data.message &&
            <div className="form-row-no-border">【メッセージ】{data.message}</div>
          }

          { (data.process_name || data.proc_type_name) &&
            <div className="form-row-no-border">【種別】{data.process_name || data.proc_type_name}</div>
          }

          { campaign_info && campaign_info.has_campaign &&
            <div className="form-row-no-border">
              【キャンペーン】{campaign_info.campaign_name}: {formatMoney(campaign_info.campaign_amount)}円
            </div>
          }

          { (cpn_apply_type === '3') &&
            <div className="form-row-no-border">【クーポン情報】条件を満たしていないためご利用できませんでした</div>
          }

          { (cpn_apply_type !== '3' && cpn_apply_type !== '1' && cpn_apply_type !== '-1') &&
            <div className="form-row-no-border">
              { cpn_id &&
                <>
                  【クーポン情報】<br />
                  クーポン番号 {cpn_id} <br/>
                </>
              }
              { repay_diff_reason === '1' && <>【返金金額差分の理由】クーポン適用のお買い物であったため<br /></> }
              {
                dscnt_amt && +dscnt_amt > 0 && proc_type && ['0002', '0005', '0006'].includes(proc_type) &&
                <>{['0002', '0005'].includes(proc_type) ? '割引金額' : '【差分金額】'} {formatMoney(dscnt_amt)}円<br /></>
              }
            </div>
          }
        </div>
      </div>
    );
  }
}
