import { ITransactionItem } from '@src/interfaces/ITransactionState';
import { formatMoney, getTimeFromTimeServer } from '@src/utils/format';
import { getAccountType, isPointAcc } from '@src/utils/functionUtils';
import React from 'react';

interface Props {
  transaction: ITransactionItem;
  expandInfo: boolean;
  isPonta: boolean;
  handleExpandInfo(): void;
}

export const AuPayTransactionItemInfo: React.FC<Props> = ({ transaction, expandInfo, isPonta, handleExpandInfo }: Props) => {
  const { fulltime, store_name, note, category, data_source, qr_info, easy_info, amount, is_income } = transaction;
  const { year, month, day } = getTimeFromTimeServer(fulltime);
  const paymentReservation = qr_info ? qr_info.payment_reservation : easy_info ? easy_info.payment_reservation : '';
  const isPoint = isPointAcc(getAccountType(data_source));
  const showStatusTran = paymentReservation === '0';
  const unitBalance = isPoint ? 'P' : '円';

  return (
    <div className="au-pay-transaction-item-bottom-line" >
      <div className={`full-width au-pay-transaction-item ${isPonta ? 'no-arrow-icon' : ''}`} onClick={isPonta ? undefined : handleExpandInfo}>
        <div className="row-flex no-padding">
          <div className="au-pay-transaction-item-infor">
            <p className="transaction-category">
              <span className="grey-small-text service-name font-kpfm">
                <span className="font-main">{`${year}/${+month}/${+day}`}</span>
              </span>
              <span className="black-bold-text store-transaction-name font-kpfm">
                <span className="font-main text-break-word"> { store_name || note || category.name }</span>
              </span>
            </p>
            <div className="transaction-amount">
              <div className={`${showStatusTran ? 'row-flex' : ''} transaction-number black-text font-roboto`}>
                {showStatusTran && <p className="transaction-status">支払い受付中</p> }
                <p className={`${is_income ? 'green-text' : 'red-text'}`}>
                  {formatMoney(amount)}
                  <span className="transaction-unit">{unitBalance}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        {!isPonta &&
         <div className="au-pay-icon-arrow">
           <img className={`arrow-down-dark ${expandInfo ? 'active' : ''}`} src="/static/media/arrow-down-dark.png" />
         </div>
        }
      </div>
    </div>
  );
};
