import { IAppState } from '../interfaces/IAppState';

export const accountListSelector = (state: IAppState) => {
  return state.accountState;
};

export const accountDetailSelector = (state: IAppState) => {
  return state.accountState.accountInfo;
};

export const accountListHistorySelector = (state: IAppState) => {
  return state.accountState.list;
};

export const maintenanceStatusSelector = (state: IAppState) => {
  return state.accountState.maintenance_status;
};
