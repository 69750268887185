import React, { PureComponent } from 'react';
import { ITransactionItem } from '@src/interfaces/ITransactionState';
import { formatMoney, getTimeFromTimeServer } from '@src/utils/format';

interface Props {
  data: ITransactionItem;
}

export class KantanKessaiOtherInfo extends PureComponent<Props> {
  render() {
    const data = this.props.data;
    const easyInfo = data.easy_info;
    const easyPayListLength = easyInfo && easyInfo.easy_pay_list.length;
    const lastElementEasyPayList = easyInfo && easyPayListLength
      ? easyInfo.easy_pay_list[easyPayListLength - 1]
      : {};
    const isNoInfo = (data: ITransactionItem) => {
      if ((easyInfo &&
        (easyInfo.pay_info_no || easyInfo.svc_tel ||
        easyInfo.use_auid_pt || easyInfo.use_pt ||
        lastElementEasyPayList.used_amount ||
        lastElementEasyPayList.paymnt_method_name)
      ) ||
      data.store_name
      ) {
        return false;
      }
      return true;
    };

    const datetime = lastElementEasyPayList.used_date
      ? getTimeFromTimeServer(
        lastElementEasyPayList.used_date.replace('Z', '+09:00')
      )
      : '';

    return (
      <div className="other-informations">
        <div className="list-form-rows">
          { isNoInfo(data)
            ? <div className="form-row-no-border"> 備考データはありません</div>
            : <>
              <div className="form-row-no-border">備考</div>
              {datetime &&
                <div className="form-row-no-border">【ご利用日】{
                  `${datetime.year}/${datetime.month}/${datetime.day}`
                }</div>
              }
              { data.store_name &&
                <div className="form-row-no-border">【サービス名】{data.store_name}</div>
              }
              { easyInfo && easyInfo.svc_tel &&
                <div className="form-row-no-border">【お問合せ先電話番号】{easyInfo.svc_tel}</div>
              }
              { easyInfo && easyInfo.use_auid_pt &&
                <div className="form-row-no-border">【ご利用Pontaポイント】{formatMoney(easyInfo.use_auid_pt)} ポイント</div>
              }
              { easyInfo && easyInfo.use_pt &&
                <div className="form-row-no-border">【ご利用auポイント】{formatMoney(easyInfo.use_pt)} ポイント</div>
              }
              { easyInfo && !!lastElementEasyPayList.used_amount &&
                <div className="form-row-no-border">【お支払金額】{formatMoney(lastElementEasyPayList.used_amount)}円</div>
              }
              { easyInfo && !!lastElementEasyPayList.paymnt_method_name &&
                <div className="form-row-no-border">【お支払方法】{lastElementEasyPayList.paymnt_method_name}</div>
              }
              { easyInfo && easyInfo.pay_info_no &&
                <div className="form-row-no-border">【決済情報番号】{easyInfo.pay_info_no}</div>
              }
            </>
          }
        </div>
      </div>
    );
  }
};
