import React from 'react';
import { ICalendarMonthItem } from '@src/interfaces/ICalendarMonth';
import Picker from 'rmc-picker';
import { getCurrentMonth, MinimumYear } from '@src/utils/dateTime';
import { getMonthYearFromTime } from '@src/utils/functionUtils';

interface PickerItem {
  text: string;
  val: string;
  class?: string;
}

interface CalendarMonthProps {
  selectedMonth?: ICalendarMonthItem;
  selectedYear: number;
  preventClickFutureTime?: boolean;
  onChangeMonth(yyyym: string): void;
  goToAssetDetail?(): void;
  maxMonth?: ICalendarMonthItem,
  maxYear?: number,
  minYearMonth?: string, // YYYY-MM
  enableAuPayText: boolean
}

interface CalendarMonthState {
  listItem: Array<PickerItem>,
  pickedItem: string;
}

export class CalendarMonth extends React.PureComponent<CalendarMonthProps, CalendarMonthState> {
  constructor(props: CalendarMonthProps) {
    super(props);

    const { selectedMonth, selectedYear } = this.props;
    let month = selectedMonth ? selectedMonth.value : getCurrentMonth();
    month = +month;
    month = (month < 10) ? `0${month}` : `${month}`;
    const pickedItem = `${selectedYear}-${month}`;

    this.state = {
      listItem: [],
      pickedItem: pickedItem
    };
  }

  componentDidMount() {
    this.initCalendarMonthValue();
  }

  componentDidUpdate(prevProps: CalendarMonthProps) {
    if (
      (this.props.selectedYear !== prevProps.selectedYear) ||
      ((this.props.selectedMonth && prevProps.selectedMonth) && (this.props.selectedMonth.value !== prevProps.selectedMonth.value)) ||
      (this.props.selectedMonth && this.props.selectedMonth.value && !prevProps.selectedMonth)
    ) {
      this.initCalendarMonthValue();
    }
  }

  initCalendarMonthValue = () => {
    const current = new Date();
    const currentYear = current.getFullYear();
    const { maxMonth, maxYear = currentYear, minYearMonth = '', enableAuPayText } = this.props;
    const maxMonthVal = maxMonth ? maxMonth.value : current.getMonth();
    const { month, year } = getMonthYearFromTime(minYearMonth);
    let months: Array<PickerItem> = [];
    const minYear = minYearMonth ? +year : MinimumYear;

    if (enableAuPayText) {
      months = months.concat([{
        text: '6か月以上前の履歴（遷移後対象の月を選択してください）',
        val: 'aupay',
        class: 'au-pay-text'
      }]);
    }

    for (var i = minYear; i <= maxYear; i++) {
      const generateNumber = i === maxYear ? +maxMonthVal : 12;
      const minMonth = (minYearMonth && i === minYear) ? +month : 1;
      const newMonths: Array<{text: string, val: string}> = [];

      for (var num = minMonth; num <= generateNumber; num++) {
        const month = (num < 10) ? `0${num}` : `${num}`;
        newMonths.push({
          text: `${i}年${+month}月`,
          val: `${i}-${month}`
        });
      }

      months = months.concat(newMonths);
    }
    this.setState({ listItem: months });
  }

  handleOnchangeMonth = (value: string) => {
    const { onChangeMonth } = this.props;

    onChangeMonth(value);
    this.setState({ pickedItem: value });
  }

  handleOnclickAuPayText = () => {
    const { goToAssetDetail } = this.props;

    goToAssetDetail && goToAssetDetail();
  }

  render() {
    const { listItem, pickedItem } = this.state;

    return (
      <div className="modal-scroll__content">
        <div className="calendar-month-container relative">
          <Picker
            defaultSelectedValue={pickedItem}
            onValueChange={(value) => this.handleOnchangeMonth(value)}
          >
            {listItem.map((item, idx) => (
              <Picker.Item
                key={idx}
                className={`picker-item ${item.class} ${pickedItem === item.val ? 'picker-item__selected' : ''}`}
                value={item.val}
              >
                {item.text}
              </Picker.Item>
            ))}
          </Picker>
        </div>
      </div>
    );
  }
}
