import { ITransactionItem } from '@src/interfaces/ITransactionState';
import React, { useState } from 'react';
import { AuPayTransactionItemInfo } from './AuPayTransactionItemInfo';
import { OtherInfo } from '@src/transaction/components/_transactionDetailContainer/OtherInfo';
import { PayNetTimeline } from '@src/transaction/components/_transactionDetailContainer/PayNetTimeline';

interface Props {
  transaction: ITransactionItem;
  isPonta: boolean;
}

export const AuPayTransactionItem = ({ transaction, isPonta }: Props) => {
  const [expandInfo, setExpandInfo] = useState(false);

  return (
    <div className="aupay-transaction-item">
      <div className="container">
        <AuPayTransactionItemInfo
          transaction={transaction}
          handleExpandInfo={() => setExpandInfo(!expandInfo)}
          expandInfo={expandInfo}
          isPonta={isPonta}
        />
      </div>
      {expandInfo &&
      <div className='au-pay-expand-info'>
        <div className="container au-pay-other-info">
          <OtherInfo data={transaction} />
        </div>
        <PayNetTimeline data={transaction}/>
      </div>
      }
    </div>
  );
};
