import { ITransactionItem } from '@src/interfaces/ITransactionState';
import { TransactionType } from '@src/utils/appContanst';
import React from 'react';
import { AuPayTransactionItem } from './AuPayTransactionItem';

interface Props {
  minHeight: string,
  time: string;
  isFilterIncomeTran: boolean,
  isFilterExpenseTran: boolean,
  incomeFilterText: string,
  isPonta: boolean,
  existTransaction: boolean,
  expenseFilterText: string;
  sortedTransaction: Array<{ [key: string]: Array<ITransactionItem> }>;
  handleOnClickFilter(transactionType: TransactionType): void;
  isLoadingTransactions: boolean;
}

export const AuPayTransaction: React.FC<Props> = ({
  time,
  minHeight,
  isFilterIncomeTran,
  isFilterExpenseTran,
  incomeFilterText,
  existTransaction,
  expenseFilterText,
  sortedTransaction,
  handleOnClickFilter,
  isLoadingTransactions,
  isPonta
}: Props) => {
  return (
    <div className="transaction-content" style={{ minHeight: minHeight }}>
      <div className="bg-basegrey">
        <div className="row-flex row-flex-justify-start container greyleft-item">
          <button
            className={`button button-white is-rounded btn-income ${isFilterIncomeTran ? '' : '__disable'}`}
            onClick={() => handleOnClickFilter(TransactionType.INCOME)}
          >
            {incomeFilterText || '収入'}
          </button>
          <button
            className={`button button-white is-rounded btn-expense ${isFilterExpenseTran ? '' : '__disable'}`}
            onClick={() => handleOnClickFilter(TransactionType.EXPENSE)}
          >
            {expenseFilterText || '支出'}
          </button>
        </div>
      </div>
      <div className="transactions">
        { /* eslint-disable-next-line @typescript-eslint/no-explicit-any */ }
        {!isLoadingTransactions && sortedTransaction.map((item: any) => {
          return item.transactions.map((transaction: ITransactionItem) => (
            <AuPayTransactionItem
              key={`au-pay-transaction-item-${transaction.id}-${time}`}
              transaction={transaction}
              isPonta={isPonta}
            />
          ));
        })}
      </div>
      {!existTransaction && !isLoadingTransactions && <div className="container message-text">履歴がありません。</div>}
      {isLoadingTransactions && <div className="container message-text">履歴データ取得中</div>}
    </div>
  );
};
