import * as React from 'react';
import { RootModalTransition } from './RootModalTransition';
import { ICalendarMonthValue } from '@src/interfaces/ICalendarMonth';
import { CalendarMonth } from '../Calendar';
import { Portal } from '@src/components/Portal';
import { clearAllBodyScrollLocks, disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

interface ModalCalendarMonthProps {
  onCloseModal?(): void;
  isOpenModal: boolean;
  preventClickFutureTime?: boolean;
  calendarMonthValue: ICalendarMonthValue;
  onSubmitChangeMonth(calendarMonthValue: ICalendarMonthValue): void;
  goToAssetDetail?(): void;
  maxMonthYear?: ICalendarMonthValue,
  minYearMonth?: string, // YYYY-MM
  enableAuPayText?: boolean
}

interface ModalCalendarMonthState {
  selectMonth: ICalendarMonthValue;
  isAuPayBtn : boolean;
}

export class ModalCalendarMonth extends React.PureComponent<ModalCalendarMonthProps, ModalCalendarMonthState> {
  constructor(props: ModalCalendarMonthProps) {
    super(props);

    const { calendarMonthValue } = this.props;
    this.state = {
      selectMonth: { ...calendarMonthValue },
      isAuPayBtn: false
    };
  }

  handleOnSubmitChangeMonth(newCalendarMonthValue: ICalendarMonthValue) {
    const { isAuPayBtn } = this.state;

    if (isAuPayBtn) {
      this.handleOnclickAuPayText();
    } else {
      const { calendarMonthValue, onSubmitChangeMonth, onCloseModal } = this.props;
      const { month, currentYear } = newCalendarMonthValue;
      const monthValue = month ? month.value : '00';
      const oldMonthValue = calendarMonthValue.month ? calendarMonthValue.month.value : '00';
      const oldCurrentYear = calendarMonthValue.currentYear;

      if (+monthValue !== +oldMonthValue || (+monthValue === +oldMonthValue && +currentYear != +oldCurrentYear)) {
        return onSubmitChangeMonth(newCalendarMonthValue);
      } else if (onCloseModal && typeof onCloseModal == 'function') {
        return onCloseModal();
      }
    }
  }

  componentDidUpdate(prevProps: ModalCalendarMonthProps) {
    if (prevProps.isOpenModal !== this.props.isOpenModal && this.props.isOpenModal === true) {
      disableBodyScroll(document.getElementsByClassName('modal-calendar')[0]);
    } else if (prevProps.isOpenModal !== this.props.isOpenModal && this.props.isOpenModal === false) {
      enableBodyScroll(document.getElementsByClassName('modal-calendar')[0]);
    }
  }

  componentWillUnmount() {
    clearAllBodyScrollLocks();
  }

  handleOnchangeMonth = (value: string) => {
    if (value === 'aupay') {
      this.setState({ isAuPayBtn: true });
    } else if (value) {
      const timeSplit = value.split('-');
      const selectYear = timeSplit.length ? timeSplit[0] : '';
      const selectMonth = timeSplit.length ? timeSplit[1] : '';

      this.setState({
        isAuPayBtn: false,
        selectMonth: {
          currentYear: +selectYear,
          month: { label: `${selectMonth}月`, value: selectMonth }
        }
      });
    }
  }

  handleOnclickAuPayText = () => {
    const { goToAssetDetail } = this.props;

    goToAssetDetail && goToAssetDetail();
  }

  render() {
    const { enableAuPayText, preventClickFutureTime, maxMonthYear, calendarMonthValue, minYearMonth } = this.props;
    const { selectMonth } = this.state;

    return (
      <Portal selectorElement="#portal-modal">
        <RootModalTransition isOpenModal={this.props.isOpenModal}>
          <div className={'modal-scroll modal-bottom-enter-done'}>
            <div className="modal-scroll__mask" />
            <div className="modal-scroll__wrapper modal-analytic-expense-history ">
              <div className="modal-scroll__container modal-scroll__analytic">
                <div className="modal-scroll_analytic_content">
                  <div className="header-modal ">
                    <p onClick={this.props.onCloseModal} className="blue-text is-bold">キャンセル</p>
                    <p onClick={() => this.handleOnSubmitChangeMonth(selectMonth)} className="blue-text is-bold">完了</p>
                  </div>
                  <CalendarMonth
                    selectedYear={calendarMonthValue.currentYear}
                    selectedMonth={calendarMonthValue.month}
                    preventClickFutureTime={preventClickFutureTime}
                    maxMonth={maxMonthYear && maxMonthYear.month}
                    maxYear={maxMonthYear && maxMonthYear.currentYear}
                    minYearMonth={minYearMonth}
                    onChangeMonth={this.handleOnchangeMonth}
                    goToAssetDetail={this.props.goToAssetDetail}
                    enableAuPayText={enableAuPayText || false}
                  />
                </div>
              </div>
            </div>
          </div>
        </RootModalTransition>
      </Portal>
    );
  }
}
