import * as React from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { clearAllBodyScrollLocks, disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

interface RootModalTransitionProps {
  isOpenModal: boolean;
  isPreventOverflow?: boolean; // handle overflow when mutiple modal open at the same timne
  children: React.ReactNode;
}

export class RootModalTransition extends React.PureComponent<RootModalTransitionProps> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  targetElement: any = null;

  componentDidUpdate(prevProps: RootModalTransitionProps) {
    const ele = document.getElementsByClassName('modal-scroll')[0];

    if (prevProps.isPreventOverflow || this.props.isPreventOverflow) {
      return;
    }
    if (prevProps.isOpenModal !== this.props.isOpenModal && this.props.isOpenModal === true) {
      if (ele) {
        disableBodyScroll(document.getElementsByClassName('modal-scroll')[0]);
      }
    } else if (prevProps.isOpenModal !== this.props.isOpenModal && this.props.isOpenModal === false) {
      if (ele) {
        enableBodyScroll(document.getElementsByClassName('modal-scroll')[0]);
      }
    }
  }

  componentWillUnmount() {
    clearAllBodyScrollLocks();
  }

  render() {
    return (
      <TransitionGroup component={null}>
        {this.props.isOpenModal && (
          <CSSTransition
            in={this.props.isOpenModal}
            timeout={300}
            classNames="modal-bottom"
            unmountOnExit
          >
            {this.props.children}
          </CSSTransition>
        )}
      </TransitionGroup>
    );
  }
}
