import React, { PureComponent } from 'react';
import { IQrDetail, IEasyPay, ITransactionItem } from '@src/interfaces/ITransactionState';
import { formatDatetimeJapan, formatDateJapan, formatMoney } from '@src/utils/format';
import __find from 'lodash.find';
import __orderBy from 'lodash.orderby';
import { isPayNetEasyPayTran, isPayNetQrTran, isStartTimeOfDay } from '@src/utils/functionUtils';

interface PayNetTimelineProps {
  data: ITransactionItem;
}

export class PayNetTimeline extends PureComponent<PayNetTimelineProps> {
  getDetailQr = () => {
    const initQRInfo = { payment_reservation: '', qr_list: [] };
    const { qr_info = initQRInfo } = this.props.data;
    const { payment_reservation = '', qr_list = [] } = qr_info;
    const sortedQrList = __orderBy(qr_list, 'proc_d_time', 'desc');
    const completedItem = __find(sortedQrList, (item: IQrDetail) => item.proc_type === '2012');
    const startQr = __find(sortedQrList, (item: IQrDetail) => item.proc_type === '2002');
    const inProcessQR = __find(sortedQrList, (item: IQrDetail) => item.proc_type === '2006');
    const firstItem = inProcessQR || startQr;

    return { paymentReservation: payment_reservation, firstItem, completedItem };
  }

  getDetailEasyPay = () => {
    const { easy_info } = this.props.data;
    const { payment_reservation = '', easy_pay_list = [] } = easy_info || {};
    const sortedList = __orderBy(easy_pay_list, 'used_date', 'desc');
    const completedItem = __find(sortedList, (item: IEasyPay) => ['83', '84'].includes(item.paymnt_method_cd));
    const startItem = __find(sortedList, (item: IEasyPay) => item.paymnt_method_cd === '80');
    const latestInProcess = __find(sortedList, (item: IEasyPay) => item.paymnt_method_cd === '81');
    const firstItem = latestInProcess || startItem;

    return { paymentReservation: payment_reservation, firstItem, completedItem };
  }

  formatShowingDateJapan = (isPayNetQr: boolean, item: any) => {
    const datetime = isPayNetQr ? item.proc_d_time : item.used_date;
    let formatedDatetime = formatDatetimeJapan(datetime);

    if (!isPayNetQr && isStartTimeOfDay(datetime)) {
      formatedDatetime = formatDateJapan(datetime);
    }

    return formatedDatetime;
  }

  render() {
    const { qr_info, easy_info, amount } = this.props.data;
    const isPayNetQr = isPayNetQrTran(qr_info);
    const { paymentReservation, firstItem, completedItem } = isPayNetQr ? this.getDetailQr() : this.getDetailEasyPay();
    const isCompleteStatus = paymentReservation === '1';
    const currentMoney = formatMoney(Math.abs(+amount));
    const isPayNet = isPayNetEasyPayTran(easy_info) || isPayNetQr;
    const validPmtReservation = ['0', '1'].includes(paymentReservation);

    if (!isPayNet || !validPmtReservation || (isCompleteStatus && !completedItem)) {
      return <></>;
    }

    return (
      <div className="form-row  __pay-net-timeline bg-basegrey">
        <div className="pay-net-info">
          { isCompleteStatus
            ? <div className="icon-timeline icon-timeline__complete"><img src={require('static/media/icon-timeline_2.svg')} /></div>
            : <div className="icon-timeline"><img src={require('static/media/icon-timeline.svg')} /></div>
          }
          <div>
            <div className={`row-flex ${isCompleteStatus ? 'grey-text' : 'black-text'} `} >
              <div>
                <p className="bold-text fz-14">{isCompleteStatus ? '支払い受付' : '支払い受付中'} </p>
                <p className="fz-12 nowrap-text">
                  {this.formatShowingDateJapan(isPayNetQr, firstItem)}
                </p>
              </div>
              <p className="bold-text fz-14"> {currentMoney}円</p>
            </div>
            <div className={`row-flex ${isCompleteStatus ? 'black-text' : 'grey-text'}`}>
              <div>
                <p className="bold-text fz-14">支払い完了 </p>
                {completedItem && <p className="fz-12 nowrap-text">
                  {this.formatShowingDateJapan(isPayNetQr, completedItem)}
                </p>}
              </div>
              {completedItem && <p className="bold-text fz-14"> {currentMoney}円</p> }
            </div>
          </div>
        </div>
      </div>
    );
  }
}
