import { withRouterParsedQuery } from '@src/hocs/withRouterParsedQuery';
import React from 'react';
import Router from 'next/router';
import AuPayHistory from '@src/au_pay';

interface Props {
  query: {[key: string]: string};
}
class TopContainer extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  // auPay/Kantan history has been deleted, except for ponta
  // users may request access to this screen (auPay/Kantan),
  // so index page is still AuPayHistory, but it needs to be redirected to top url instead
  componentDidMount() {
    Router.push({
      pathname: '/asset/au_pay',
      query: { ...this.props.query }
    });
  }

  render() {
    return <AuPayHistory />;
  }
}

export default withRouterParsedQuery(TopContainer);
