import React from 'react';
import Router from 'next/router';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import getConfig from 'next/config';
import moment from '@src/utils/kpfmMoment';
import { filterTransactions, getDataAuPayHistory, updateParams } from '@src/actions/historyActions';
import { withRouterParsedQuery } from '@src/hocs/withRouterParsedQuery';
import { IAppState } from '@src/interfaces/IAppState';
import { ICalendarMonthValue } from '@src/interfaces/ICalendarMonth';
import { Portal } from '@src/components/Portal';
import { IHistoryParams } from '@src/interfaces/IHistoryState';
import { getAccountType, getAssetCurrentTime, getMonthYearFromTime, getShowingMonth, handleSort, sumIncomeExpense } from '@src/utils/functionUtils';
import { ModalCalendarMonth } from '@src/components/ModalBottom/ModalCalendarMonth';
import AuPaySummary from './components/AuPaySummary';
import { AuPayTransaction } from './components/AuPayTransaction';
import { ITransactionItem } from '@src/interfaces/ITransactionState';
import { AccountType, pathConstant, TransactionType } from '@src/utils/appContanst';
import { auPayAnalyticsEvents } from '@src/analyticsEvents/auPayHistortAnalyticsEvents';
import { loadDataAccount } from '@src/actions/accountActions';
import { accountListSelector } from '@src/selectors/accountSelector';
import { IAccountState } from '@src/interfaces/IAccountState';

interface Props {
  query: {[key: string]: string}
  params: IHistoryParams | undefined;
  isFilterIncomeTran: boolean;
  isFilterExpenseTran: boolean;
  filterTransactions: Array<ITransactionItem>;
  isLoading: boolean;
  isLoadingTransactions: boolean;
  accountList: IAccountState;
  loadDataAccount(): void;
  filterTransactionsAction(transactionType: TransactionType) : void;
  updateParams(name: string, value: string): void;
  getDataAuPayHistory(time: string, service: string): void;
}

interface State {
  openCalendarMonth: boolean;
  calendarMonthValue: ICalendarMonthValue;
  sortedTransaction: Array<{ [key: string]: Array<ITransactionItem> }>;
  minHeight: string;
  auPayBtnHeight: number;
}

class AuPayHistory extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const { params = { time: '' } } = this.props;
    const { month, year } = getMonthYearFromTime(params.time || '');
    const currentMonth = getShowingMonth(month);

    this.state = {
      auPayBtnHeight: 45,
      minHeight: 'calc(100vh - 230px)',
      openCalendarMonth: false,
      sortedTransaction: [],
      calendarMonthValue: {
        currentYear: parseInt(year),
        month: { value: parseInt(month), label: `${currentMonth}月` }
      }
    };
  }

  componentDidMount() {
    const auPayBtn = document.getElementById('au-pay-btn');
    const auPayInfo = document.getElementById('au-pay-info');

    this.getTransactionHistory();
    this.props.loadDataAccount();
    auPayBtn && this.setState({ auPayBtnHeight: auPayBtn.offsetHeight });
    auPayInfo && this.setState({ minHeight: `calc(100vh - ${auPayInfo.offsetHeight + 45}px)` });
  }

  componentDidUpdate(prevProps: Props) {
    const { filterTransactions } = this.props;
    const { auPayBtnHeight } = this.state;
    const filterSelect = { id: '1', label: '履歴が新しい順', value: '' };
    const prevTransactions = prevProps.filterTransactions;
    const auPayBtn = document.getElementById('au-pay-btn');
    const auPayInfo = document.getElementById('au-pay-info');

    // call API get history transactions success
    if (prevTransactions && filterTransactions && prevTransactions !== filterTransactions) {
      this.setState({ sortedTransaction: handleSort(filterSelect.id, filterTransactions) });
    }

    if (auPayBtn && auPayBtnHeight !== auPayBtn.offsetHeight) {
      auPayInfo && this.setState({ minHeight: `calc(100vh - ${auPayInfo.offsetHeight + 45}px)` });
      this.setState({ auPayBtnHeight: auPayBtn.offsetHeight });
    }
  }

  handleSubmitChangeMonth = (calendarMonthValue: ICalendarMonthValue) => {
    if (calendarMonthValue.month) {
      const time = `${calendarMonthValue.currentYear}-${calendarMonthValue.month.value}`;
      this.props.updateParams('time', time);
      this.getDataAuPayHistory(time);
    }
  }

  getTransactionHistory = () => {
    const { params = { time: '' } } = this.props;
    this.getDataAuPayHistory(params.time || '');
  }

  getDataAuPayHistory(time: string) {
    const { query } = this.props;
    const { month, year } = getMonthYearFromTime(time);
    const currentMonth = getShowingMonth(month);
    const calendarMonthValue = {
      currentYear: parseInt(year),
      month: { value: parseInt(month), label: `${currentMonth}月` }
    };

    this.setState({ calendarMonthValue, openCalendarMonth: false });
    this.props.getDataAuPayHistory(time, query.service || 'aupay');
  }

  handleCalendarModal = () => {
    this.setState((prevState) => ({
      openCalendarMonth: !prevState.openCalendarMonth
    }));
  }

  handleOnClickFilter = (transactionType: TransactionType) => {
    this.props.filterTransactionsAction(transactionType);
  }

  handleOnclickCampaignText = () => {
    auPayAnalyticsEvents.eventClickCampaignText();
    const { publicRuntimeConfig = {} } = getConfig() || {};
    const { APP_DOMAIN, CAMPAIGN_DNS } = publicRuntimeConfig;

    window.location.href = `${APP_DOMAIN}${CAMPAIGN_DNS}`;
  }

  gotoTopPage = () => {
    auPayAnalyticsEvents.eventClickAuPayTop();
    Router.push(pathConstant.topPath);
  };

  linkToCampaignList = () => {
    const { publicRuntimeConfig = {} } = getConfig() || {};
    const { APP_DOMAIN, CAMPAIGN_DNS } = publicRuntimeConfig;

    window.location.href = `${APP_DOMAIN}${CAMPAIGN_DNS}`;
  };

  goToAssetDetail = () => {
    const { accountList, query } = this.props;
    const auPayAccounts = accountList.au || [];
    const auPay = auPayAccounts.find((item) => getAccountType(item.data_source) === AccountType.au_prepaid);
    const auKantan = auPayAccounts.find((item) => getAccountType(item.data_source) === AccountType.au_kantan_kessai);
    const auPonta = auPayAccounts.find((item) => getAccountType(item.data_source) === AccountType.au_ponta);

    if (query.service === 'kantan' && auKantan && !auKantan.is_hide) {
      Router.push(`/asset/detail/?dataSource=${auKantan.data_source}&id=${auKantan.id}`);
    } else if (query.service === 'ponta' && auPonta && !auPonta.is_hide) {
      Router.push(`/asset/detail/?dataSource=${auPonta.data_source}&id=${auPonta.id}`);
    } else if (auPay && !auPay.is_hide) {
      Router.push(`/asset/detail/?dataSource=${auPay.data_source}&id=${auPay.id}`);
    } else {
      Router.push(pathConstant.topPath);
    }
  }

  getHistoryTitle(typeSer: string|undefined) {
    switch (typeSer) {
      case 'aupay':
        return 'au PAY取引履歴';
      case 'ponta':
        return 'Pontaポイント利用履歴';
      case 'kantan':
        return 'auかんたん決済取引履歴';
      default: return 'au PAY取引履歴';
    }
  }

  render() {
    const { isLoading, filterTransactions, query, isFilterExpenseTran, isFilterIncomeTran, isLoadingTransactions } = this.props;
    const { openCalendarMonth, calendarMonthValue, sortedTransaction, minHeight } = this.state;
    const currentMonth = moment().format('MM');
    const { currentYear, month = { value: currentMonth } } = calendarMonthValue;
    const time = `${currentYear}-${month.value}`;
    const { expense, income } = sumIncomeExpense(filterTransactions, true);
    const existTransaction = !!filterTransactions.length;
    const isPonta = query.service === 'ponta';
    const isKantan = query.service === 'kantan';
    const service = query.service || 'aupay';
    const isAuPay = service === 'aupay';
    const enableButton = query.issdk !== '1';
    const filterText = isPonta
      ? { expense: '利用', income: '獲得' }
      : isKantan
        ? { expense: 'ご利用額', income: '収入' }
        : { expense: '支出', income: 'チャージ' };
    const historyTitle = this.getHistoryTitle(query.service);

    return (
      <div className="au-pay-container">
        <div className="history-container">
          <div id='au-pay-info'>
            <div className="container row-flex au-pay-title">
              <p className="is-bold">{historyTitle}</p>
              <div className="link-blue fz-10" id='au-pay-btn' onClick={() => this.handleOnclickCampaignText()}>
                {enableButton && isAuPay &&
                <img
                  className='btn-campaign-au-pay'
                  onClick={this.linkToCampaignList}
                  src={require('static/media/btn-campaign-au-pay.png')}
                />
                }
              </div>
            </div>
            <AuPaySummary
              time={time}
              totalExpense={isLoading ? '－' : expense}
              totalIncome={isLoading ? '－' : income}
              handleCalendarModal={this.handleCalendarModal}
              balanceUnit={isPonta ? 'P' : '円'}
              totalExpenseText={filterText.expense}
              totalIncomeText={filterText.income}
            />
          </div>
          <AuPayTransaction
            isFilterIncomeTran={isFilterIncomeTran}
            isFilterExpenseTran={isFilterExpenseTran}
            incomeFilterText={`${isLoading ? '－' : filterText.income}`}
            expenseFilterText={`${isLoading ? '－' : filterText.expense}`}
            sortedTransaction={sortedTransaction}
            handleOnClickFilter={this.handleOnClickFilter}
            time={time}
            existTransaction={existTransaction}
            minHeight={minHeight}
            isPonta={isPonta}
            isLoadingTransactions={isLoadingTransactions}
          />
          {/* {enableButton && existTransaction &&
          <div className="red-button bg-white">
            <div className="red-button-content row-flex row-flex-justify-center red-text is-bold">
            カテゴリ分類などの詳細情報は、お金の管理からご確認ください
            </div>
          </div>
          } */}
        </div>
        <Portal selectorElement='#portal-modal'>
          <ModalCalendarMonth
            isOpenModal={openCalendarMonth}
            onCloseModal={this.handleCalendarModal}
            calendarMonthValue={calendarMonthValue}
            onSubmitChangeMonth={this.handleSubmitChangeMonth}
            preventClickFutureTime={true}
            maxMonthYear={getAssetCurrentTime()}
            minYearMonth={moment().subtract(5, 'months').format('YYYY-MM')}
            goToAssetDetail={this.goToAssetDetail}
            enableAuPayText={true}
          />
          {enableButton &&
          // <div className='aupay-button' onClick={this.gotoTopPage}>
          //   <div className='aupay-button__content'>
          //     <img className='aupay-button__icon' id='top-icon' src={'/static/media/icon-aupay.png'}/>
          //     <p>
          //       au Pay 以外の取引履歴を確認 <br/> (お金の管理へ)
          //     </p>
          //   </div>
          // </div>
           <img className='top-icon' id='top-icon' src={'/static/media/icon-top_au-pay.png'} onClick={this.gotoTopPage}/>
          }
        </Portal>
      </div>
    );
  }
};
const mapStateToProps = (state: IAppState) => ({
  isFilterIncomeTran: state.historyState.isFilterIncomeTran,
  isFilterExpenseTran: state.historyState.isFilterExpenseTran,
  filterTransactions: state.historyState.filterTransactions,
  params: state.historyState.params,
  isLoadingTransactions: state.historyState.isLoadingTransactions,
  isLoading: state.commonState.isLoading,
  accountList: accountListSelector(state)
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  loadDataAccount: () => dispatch(loadDataAccount()),
  updateParams: (name: string, value: string) => dispatch(updateParams(name, value)),
  getDataAuPayHistory: (time: string, service: string) => dispatch(getDataAuPayHistory(time, service)),
  filterTransactionsAction: (transactionType: TransactionType) => dispatch(filterTransactions(transactionType))
});

export default withRouterParsedQuery(connect(mapStateToProps, mapDispatchToProps)(AuPayHistory));
